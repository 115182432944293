<template>
  <div>
    <el-card class="box-card">
        <el-page-header @back="$router.go(-1)" content="日志详情">
</el-page-header>
<br/>
      <el-row :gutter="20">
        <el-col :span="2">拼团名称:</el-col>
        <el-col :span="5">{{ details.groupBuyName }}</el-col>
      </el-row>
      <br/>
      <el-row :gutter="20">
        <el-col :span="2">修改时间:</el-col>
        <el-col :span="5">{{ details.addTime }}</el-col>
      </el-row>
      <br/>
      <el-row :gutter="20">
        <el-col :span="2">修改人:</el-col>
        <el-col :span="5">{{ details.masterName }}</el-col>
      </el-row>
      <br/>
      <el-row :gutter="20">
        <el-col :span="2">修改类型:</el-col>
        <el-col :span="5">{{ details.updateType }}</el-col>
      </el-row>
      <br/>
      <el-row :gutter="20">
        <el-col :span="2">修改前:</el-col>
        <el-col :span="5" v-if="details.logType == 14">
          <el-image
            style="width: 100px; height: 100px"
            :src="details.curContent"
            :preview-src-list="[details.curContent]"
          >
          </el-image>
        </el-col>
        <el-col :span="5" v-else-if="details.logType == 29">
            <div v-html="details.curContent"></div>
        </el-col>
        <el-col :span="5" v-else>{{
          details.curContent
        }}</el-col>
      </el-row>
      <br/>
      <el-row :gutter="20">
        <el-col :span="2">修改后:</el-col>
        <el-col :span="5" v-if="details.logType == 14">
          <el-image
            style="width: 100px; height: 100px"
            :src="details.endContent"
            :preview-src-list="[details.endContent]"
          >
          </el-image>
        </el-col>
        <el-col :span="5" v-else-if="details.logType == 29">
            <div v-html="details.endContent"></div>
        </el-col>
        <el-col :span="5" v-else>
            <div>{{ details. endContent}}</div>
        </el-col>
      </el-row>
      <br/>
      <br/>
      <el-button type="primary" @click="$router.go(-1)"
          >返回</el-button
        >
    </el-card>
  </div>
</template>

<script>
import { logListDetails } from "../../../api/groupBuy.js";

export default {
  data() {
    return {
      details: {},
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    async getDetails() {
      const { data } = await logListDetails({ logId: this.$route.query.id });
      this.details = data.data;
    },
  },
};
</script>

<style>
</style>